@import "~bootstrap/scss/bootstrap.scss";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";

.visitor-registration {
  @extend .d-flex;
  @extend .flex-column;
  @extend .align-items-center;
  @extend .justify-content-center;

  height: 100vh;

  .header {
    @extend .d-flex;
    @extend .flex-column;
    @extend .align-items-center;
    @extend .justify-content-center;
    @extend .px-3;
    @extend .w-100;

    min-height: 120px;
    max-height: 120px;

    img {
      @extend .w-100;
    }

    .language-changer {
      @extend .mt-2;
      @extend .d-flex;
      gap: 1rem;

      .badge {
        width: 50px;
        height: 25px;

        &.text-bg-light {
          background-color: #e4e4e4 !important;

          color: #3957a7;
        }
      }
    }
  }

  .content {
    @extend .flex-grow-1;
    @extend .w-100;

    @extend .px-3;

    > .home {
      @extend .d-flex;
      @extend .flex-column;
      @extend .align-items-center;
      @extend .justify-content-center;

      gap: 2rem;

      height: 100%;
      width: 100%;

      .btn {
        @extend .d-flex;
        @extend .flex-column;
        @extend .align-items-center;
        @extend .justify-content-center;

        width: 80%;
        height: 40%;
        font-size: 42px;

        i {
          @extend .pt-2;
          font-size: 64px;
        }
      }
    }

    > .register {
      .form-row {
        @extend .mb-1;
      }

      button {
        @extend .mt-2;
        @extend .w-100;
      }
    }
  }
}
